@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("montserrat-latin-700-normal.6484fdfd.woff2") format("woff2"), url("montserrat-latin-700-normal.da451430.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("montserrat-latin-600-normal.ec13fc10.woff2") format("woff2"), url("montserrat-latin-600-normal.50d8658f.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("montserrat-latin-500-normal.ebc6cdce.woff2") format("woff2"), url("montserrat-latin-500-normal.bc2679b2.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("montserrat-latin-400-normal.ac08c953.woff2") format("woff2"), url("montserrat-latin-400-normal.84e5cdd3.woff") format("woff");
}

@font-face {
  font-family: InterVariable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-cyrillic-variable-wghtOnly-normal.27a979c7.woff2") format("woff2");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: InterVariable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-cyrillic-ext-variable-wghtOnly-normal.b4ee6181.woff2") format("woff2");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: InterVariable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-greek-variable-wghtOnly-normal.07d51911.woff2") format("woff2");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: InterVariable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-greek-ext-variable-wghtOnly-normal.1ee2a42f.woff2") format("woff2");
  unicode-range: U+1F??;
}

@font-face {
  font-family: InterVariable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-latin-variable-wghtOnly-normal.f41233f0.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: InterVariable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-latin-ext-variable-wghtOnly-normal.e4df8e16.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: InterVariable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("inter-vietnamese-variable-wghtOnly-normal.bfe55e99.woff2") format("woff2");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}
/*# sourceMappingURL=index.036fb04b.css.map */
